import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardBody } from '@gs-ux-uitoolkit-react/card';
import './index.scss';
import { ListGroup, ListGroupItem } from '@gs-ux-uitoolkit-react/list-group';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import useFetchInvestmentPolicyData from '../../../../hooks/useInvestmentPolicyCard';
import CardError from '../../../DashboardCards/Card/CardError';
import { ThreeSquaresLoader } from '../../../../../../../components/core/Loaders';
import translator from '../../../../../../../services/translator';

const { translate: t } = translator;

const MultiOrgInvestmentPolicyBody = ({ userGuid }) => {
  const [rowData, setRowData] = useState([]);
  const { loading, doFetchInvestmentPolicies, investmentPolicyData, error } = useFetchInvestmentPolicyData();
  const INVESTMENT_POLICY_CARD_LIMIT = 5;

  const fetchPolicies = useCallback(() => {
    const payload = {
      'level': 'GUID',
      userGuid
    };
    doFetchInvestmentPolicies(payload);

  }, [userGuid]);

  useEffect(() => {
    fetchPolicies();
  }, []);

  useEffect(() => {
    if (investmentPolicyData) {
      const { investmentPolicies = [] } = investmentPolicyData;
      const policiesMap = investmentPolicies
        .sort((a, b) => new Date(a.modifiedAt) - new Date(b.modifiedAt))
        .slice(0, INVESTMENT_POLICY_CARD_LIMIT)
        .map(policy => ({
          policyName: policy.policyName,
          gtamPolicyId: policy.gtamPolicyId,
          modifiedAt: policy.modifiedAt,
          createdAt: policy.createdAt,
          organizationId: policy.organizationId
        }));
      setRowData(prevRowData => JSON.stringify(prevRowData) !== JSON.stringify(policiesMap) ? policiesMap : prevRowData);
    }
  }, [investmentPolicyData]);

  return (
    <CardBody className='dbcard__investment-policy-body'>
      {!loading && error ? (
          <CardError errorMessage={error ? t('tkDashboardCardError') : t('tkFilterNoResultsFound')} />
        ) : (!loading &&  rowData?.length === 0) ? (
        <div className={'dbcard__noInvestmentPolicies'}>
          <span><Icon name='info'
                      type='outlined'
                      className='dbcard__noInvestmentPoliciesIcon'
          />{t('tkNoInvestmentPolicies')}</span>
        </div>
      ) : loading ? (
        <ThreeSquaresLoader />
      ) : (
        rowData && (
          <ListGroup>
            {rowData.map((row) => (
              <ListGroupItem>
                <Row className='dbcard__investment-policy-multi-row'>
                  <Col className='dbcard__investment-policy-multi-row-name'>
                    <Icon name='verified-user'
                          type='filled'
                          className='dbcard__investment-policy-title-icon'
                  />{row.policyName}</Col>
                  <Col className='dbcard__investment-policy-multi-org'>{row.organizationId}</Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        )
      )}
    </CardBody>
  );

};

MultiOrgInvestmentPolicyBody.propTypes = {
  userGuid: PropTypes.string
};

export default memo(MultiOrgInvestmentPolicyBody);
