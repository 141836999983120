import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaintenanceAlerts from '../UserDetails/Features/MaintenanceAlerts/MaintenanceAlerts';
import DummyCards from './DummyCards';
import CardError from './Card/CardError';
import { dashboardCardsError, getDashboardCardsPermissions, getOrganizationId } from '../../selectors';
import translator from '../../../../../services/translator';
import InvestmentPolicies from '../UserDetails/Features/InvestmentPolicy/InvestmentPolicy';
import MultiOrgInvestmentPolicies from '../UserDetails/Features/InvestmentPolicy/MultiOrgInvestmentPolicy';
import { isWithinTargetEnvironment } from '../../../../../utils/dom';
import { VALID_ENVS_FOR_INVST_POLICIES } from '../../constants';
import { userGuidSelector } from '../../../../../selectors/user';

const { translate: t } = translator;

const DashboardCards = ({ permissions, dashboardError, organizationId, userGuid }) => {
  const dashboardCardsConfig = useMemo(() => [
    {
      display: permissions.includes('MaintenanceAlerts'),
      Component: MaintenanceAlerts,
      moduleName: 'MaintenanceAlerts',
    },
    {
      display: permissions.includes('Users'),
      Component: DummyCards,
      moduleName: 'Users',
    },
    {
      display: permissions.includes('Organizations'),
      Component: DummyCards,
      moduleName: 'Organizations',
    },
    {
      display: permissions.includes('PendingApprovals'),
      Component: DummyCards,
      moduleName: 'Approvals',
    },
    // TODO: remove this condition once the feature is available in all environments
    ...(isWithinTargetEnvironment(VALID_ENVS_FOR_INVST_POLICIES)
    ? [
        {
          display: permissions.includes('InvestmentPolicy'),
          Component: InvestmentPolicies,
          moduleName: 'InvestmentPolicy',
          props: { organizationId },
        },
        {
          display: permissions.includes('MultiOrgInvestmentPolicy'),
          Component: MultiOrgInvestmentPolicies,
          moduleName: 'MultiOrgInvestmentPolicy',
          props: { userGuid },
        },
      ]
    : []),
  ].filter(card => card.display), [permissions, organizationId, userGuid]);
  return (
    permissions.length ? (
      <div className='usersearch-dashboardCards'>
        {dashboardCardsConfig.map(({ Component, moduleName, props = {} }) => (
          <Component key={moduleName} moduleName={moduleName} {...props} />
        ))}
      </div>
    ) : (
      dashboardError && <CardError errorMessage={t('tkDashboardCardError')} allModulesError />
    )
  );
};

const mapStateToProps = state => ({
  permissions: getDashboardCardsPermissions(state),
  dashboardError: dashboardCardsError(state),
  organizationId: getOrganizationId(state),
  userGuid: userGuidSelector(state),
});

DashboardCards.propTypes = {
  permissions: PropTypes.array,
  dashboardError: PropTypes.bool,
  organizationId: PropTypes.string,
  userGuid: PropTypes.string,
};

export default connect(mapStateToProps)(DashboardCards);
